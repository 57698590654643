import camelcaseKeys from "camelcase-keys";
import { IApiContract } from "./api-contract.type";
import { ICampaignList } from "../../domain/campaign";
import { match } from "ts-pattern";

function channelHandler(
  channel: IApiContract["channel"]
): ICampaignList["channel"] {
  return match<IApiContract["channel"], ICampaignList["channel"]>(channel)
    .with("email", () => "email")
    .with("whatsapp", () => "whatsapp")
    .otherwise(() => "sms");
}

function stateHandler(state: IApiContract["state"]): ICampaignList["state"] {
  return match<IApiContract["state"], ICampaignList["state"]>(state)
    .with("Active", () => "active")
    .with("Draft", () => "draft")
    .with("Sent", () => "sent")
    .with("Scheduled", () => "scheduled")
    .otherwise(() => "draft");
}

export function responseTransformer(resource: IApiContract): ICampaignList {
  return {
    ...camelcaseKeys(resource, { deep: true }),
    name: resource.name ?? "",
    channel: channelHandler(resource.channel),
    state: stateHandler(resource.state),
    audience: resource.expected_audience,
    scheduledTo: resource.scheduled_to
      ? new Date(resource.scheduled_to)
      : undefined,
    cost: {
      amount: resource.cost.value,
      currency: resource.cost.currency,
    },
  };
}
