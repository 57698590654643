import { fetcher } from "@/core/fetcher";
import type { IRequest } from "./request.type";
import { CampaignRepoError } from "../repo-error";
import { reportAndReturnUnexpectedError } from "@/module/common";

export const deleteSmsCampaign = async (request: IRequest): Promise<void> => {
  try {
    await fetcher.delete(
      `marketing/businesses/${request.businessId}/sms-campaigns/${request.campaignId}`
    );
  } catch (err: unknown) {
    const throwableError = reportAndReturnUnexpectedError<
      CampaignRepoError,
      CampaignRepoError["code"]
    >(CampaignRepoError, err, "deleteSmsCampaign", "unknown");

    throw throwableError;
  }
};
