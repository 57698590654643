










































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref } from "@vue/composition-api";
import { t } from "@/i18n";
import {
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomTextTagEnum,
  AtomButton,
  MolIframeModal,
} from "@/v2/new-design-system";

const css = bemBuilder("campaign-sms-promoter");

export default defineComponent({
  name: "CampaignSmsList",
  components: { AtomText, AtomButton, MolIframeModal },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const showTypeformModal = ref(false);
    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      showTypeformModal,
      AtomTextTagEnum,
    };
  },
});
