











import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { t } from "@/i18n";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
} from "@/v2/new-design-system";

const css = bemBuilder("campaign-email-list");

export default defineComponent({
  name: "CampaignEmailList",
  components: { AtomText },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
