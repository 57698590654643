import { IApiContract } from "./api-contract.type";
import { ICampaignDetail } from "../../domain/campaign";
import { match } from "ts-pattern";

const channelHandler = (
  channel: IApiContract["channel"]
): ICampaignDetail["channel"] => {
  return match<IApiContract["channel"], ICampaignDetail["channel"]>(channel)
    .with("sms", () => "sms")
    .with("email", () => "email")
    .with("whatsapp", () => "whatsapp")
    .otherwise(() => "sms");
};

const stateHandler = (
  state: IApiContract["state"]
): ICampaignDetail["state"] => {
  return match<IApiContract["state"], ICampaignDetail["state"]>(state)
    .with("active", () => "active")
    .with("draft", () => "draft")
    .with("sent", () => "sent")
    .with("scheduled", () => "scheduled")
    .otherwise(() => "draft");
};

export const responseTransformer = (
  resource: IApiContract
): ICampaignDetail => {
  return {
    id: resource.id,
    name: resource.name ?? "",
    message: resource.text ?? "",
    channel: channelHandler(resource.channel.toLowerCase()),
    state: stateHandler(resource.state.toLowerCase()),
    scheduledTo: resource.scheduled_to
      ? new Date(resource.scheduled_to)
      : undefined,
    audience: resource.expected_audience,
    sendImmediately: resource.send_immediately,
    messagingServiceId: resource.messaging_service_id ?? "",
  };
};
