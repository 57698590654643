import { fetcher, FetcherHTTPError } from "@/core/fetcher";
import { responseTransformer } from "./response-transformer";
import type { IApiContract } from "./api-contract.type";
import type { IRequest } from "./request.type";
import { CampaignRepoError } from "../repo-error";
import { reportAndReturnUnexpectedError } from "@/module/common";
import { ICampaignDetail } from "../../domain/campaign";

const THIRTY_SECONDS = 1000 * 30;

export async function fetchSmsCampaign(
  request: IRequest
): Promise<ICampaignDetail> {
  try {
    const campaign = await fetcher.get<IApiContract>(
      `marketing/businesses/${request.businessId}/sms-campaigns/${request.campaignId}`,
      {
        cacheTTL: THIRTY_SECONDS,
      }
    );

    return responseTransformer(campaign);
  } catch (err: unknown) {
    if (err instanceof FetcherHTTPError && err.status === 404) {
      throw new CampaignRepoError(
        "fetchSmsCampaign",
        "Campaign not found",
        "campaign_not_found",
        err
      );
    }

    const throwableError = reportAndReturnUnexpectedError<
      CampaignRepoError,
      CampaignRepoError["code"]
    >(CampaignRepoError, err, "fetchSmsCampaign", "unknown");

    throw throwableError;
  }
}
