import { fetcher } from "@/core/fetcher";
import type { IRequest } from "./request.type";
import { CampaignRepoError } from "../repo-error";
import { reportAndReturnUnexpectedError } from "@/module/common";
import { IBody } from "./body.type";

export const updateSmsCampaign = async (
  request: IRequest
): Promise<boolean> => {
  try {
    await fetcher.put<undefined, IBody>(
      `marketing/businesses/${request.businessId}/sms-campaigns/${request.campaignId}`,
      {
        body: {
          text: request.content,
          messaging_service_id: request.senderId,
          name: request.title,
          send_immediately: request.scheduledTo ? false : true,
          ...(request.scheduledTo
            ? { scheduled_to: request.scheduledTo?.toJSON() }
            : {}),
        },
      }
    );

    return true;
  } catch (err: unknown) {
    const throwableError = reportAndReturnUnexpectedError<
      CampaignRepoError,
      CampaignRepoError["code"]
    >(CampaignRepoError, err, "updateSmsCampaign", "unknown");

    throw throwableError;
  }
};
