


































import { bemBuilder } from "@chatfood/core-utils";
import { computed, defineComponent } from "@vue/composition-api";
import { t } from "@/i18n";
import {
  AtomDate,
  AtomIcon,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomTextTagEnum,
} from "@/v2/new-design-system";

const css = bemBuilder("campaign-sms-preview");

export default defineComponent({
  name: "CampaignSmsPreview",
  components: { AtomText, AtomDate, AtomIcon },
  props: {
    senderId: {
      type: String,
      default: undefined,
    },
    content: {
      type: String,
      required: true,
    },
    scheduledAt: {
      type: Date,
      default: undefined,
    },
  },
  setup(props) {
    const linkifiedContent = computed(() => {
      const urlRegex = /((http(s)?:\/\/)|(www\.))([^\s]+[^.,\s])/g;
      return props.content.replace(urlRegex, function (match) {
        let href = match;
        if (!match.startsWith("http")) {
          href = "http://" + match;
        }
        return '<a href="' + href + '" target="_blank">' + match + "</a>";
      });
    });

    return {
      t,
      css,
      linkifiedContent,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomTextTagEnum,
    };
  },
});
