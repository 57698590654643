import { fetchSmsCampaigns } from "./fetch-sms-campaigns";
import { fetchSmsCampaign } from "./fetch-sms-campaign";
import { fetchSenderIds } from "./fetch-sender-ids";
import { startSmsCampaign } from "./start-sms-campaign";
import { updateSmsCampaign } from "./update-campaign";
import { markCampaignAsReady } from "./mark-campaign-as-ready";
import { deleteSmsCampaign } from "./delete-sms-campaign";

export const campaignRepo = {
  getCampaigns: fetchSmsCampaigns,
  getSmsCampaign: fetchSmsCampaign,
  getSenderIds: fetchSenderIds,
  startCampaign: startSmsCampaign,
  updateCampaign: updateSmsCampaign,
  markCampaignAsReady: markCampaignAsReady,
  deleteSmsCampaign: deleteSmsCampaign,
};
