import { fetcher } from "@/core/fetcher";
import { responseTransformer } from "./response-transformer";
import type { IApiContract } from "./api-contract.type";
import type { ISearchParams } from "./search-params.type";
import type { IRequest } from "./request.type";
import { CampaignRepoError } from "../repo-error";
import { ISenderId } from "../../domain/sender-id";
import { reportAndReturnUnexpectedError } from "@/module/common";
import { match } from "ts-pattern";
import { IDomainApiResponseList } from "@/module/common/domain/api-response-list";

const channelHandler = (
  channel: IRequest["channel"]
): ISearchParams["channel"] => {
  return match<IRequest["channel"], ISearchParams["channel"]>(channel)
    .with("sms", () => "Sms")
    .with("whatsapp", () => "WhatsApp")
    .with("email", () => "Email")
    .otherwise(() => "Sms");
};

const THIRTY_SECONDS = 1000 * 30;

export const fetchSenderIds = async (
  request: IRequest
): Promise<Array<ISenderId>> => {
  try {
    const res = await fetcher.get<
      IDomainApiResponseList<IApiContract>,
      ISearchParams
    >(`messaging/businesses/${request.businessId}/messaging-services`, {
      cacheTTL: THIRTY_SECONDS,
      queryString: {
        channel: channelHandler(request.channel),
      },
    });

    return res.data.map((senderId) => responseTransformer(senderId));
  } catch (err: unknown) {
    const throwableError = reportAndReturnUnexpectedError<
      CampaignRepoError,
      CampaignRepoError["code"]
    >(CampaignRepoError, err, "fetchSenderIds", "unknown");

    throw throwableError;
  }
};
