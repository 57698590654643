import { fetcher } from "@/core/fetcher";
import type { IRequest } from "./request.type";
import { CampaignRepoError } from "../repo-error";
import { reportAndReturnUnexpectedError } from "@/module/common";
import { IBody } from "./body.type";

export const startSmsCampaign = async (request: IRequest): Promise<void> => {
  try {
    await fetcher.post<undefined, IBody>(
      `marketing/businesses/${request.businessId}/sms-campaigns`,
      {
        body: {
          csv_path: request.csvPath,
        },
      }
    );
  } catch (err: unknown) {
    const throwableError = reportAndReturnUnexpectedError<
      CampaignRepoError,
      CampaignRepoError["code"]
    >(CampaignRepoError, err, "startSmsCampaign", "unknown");

    throw throwableError;
  }
};
