var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css('')},[(_vm.isLoading)?_c('AtomLoading',{attrs:{"floating":""}}):(_vm.campaigns.length)?_c('div',[_c('OrgTable',{class:_vm.css('datatable'),attrs:{"has-actions":"","loading":_vm.isLoadingTable,"columns":_vm.columns,"total-records":_vm.pagination ? _vm.pagination.perPage : 0,"data":_vm.campaigns,"on-sort":_vm.onTableSort,"no-records-msg":_vm.t('module.campaign.sms_list.no_records_found_message'),"data-test":"table"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(_vm._s(cell))])]}},{key:"scheduledTo",fn:function(ref){
var cell = ref.cell;
return [(cell)?_c('AtomText',[_c('i18n',{attrs:{"path":"module.campaign.sms_list.scheduled_at_display"},scopedSlots:_vm._u([{key:"date",fn:function(){return [_c('AtomDate',{attrs:{"datetime":cell,"format":"MMMM DD YYYY"}})]},proxy:true},{key:"timeRangeFrom",fn:function(){return [_c('AtomDate',{attrs:{"datetime":cell,"format":"H:mm"}})]},proxy:true},{key:"timeRangeTo",fn:function(){return [_c('AtomDate',{attrs:{"datetime":_vm.addMinutes(cell, 15),"format":"H:mm"}})]},proxy:true}],null,true)})],1):_vm._e()]}},{key:"audience",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(_vm._s(cell))])]}},{key:"totalCost",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('AtomMoney',{attrs:{"amount":cell.amount,"currency":cell.currency}})],1)]}},{key:"delivered",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(" "+_vm._s(cell)+" ")])]}},{key:"state",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('CampaignStateLabel',{attrs:{"state":cell}})],1)]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [(row.state === 'draft')?_c('div',[_c('MolTableAction',{attrs:{"icon-name":"pencil","on-click":function () { return _vm.editCampaign(row.id); },"tooltip-text":_vm.t('module.campaign.sms_list.edit_cta')}}),_c('MolTableAction',{attrs:{"icon-name":"delete","type":_vm.MolTableActionTypeEnum.DANGER,"on-click":function () { return (_vm.deleteCampaignModal = {
                  id: row.id,
                  name: row.name,
                }); },"tooltip-text":_vm.t('module.campaign.sms_list.delete_cta')}})],1):_vm._e()]}}])}),(_vm.pagination)?_c('MolPagination',{attrs:{"current-page":_vm.pagination.currentPage,"last-page":_vm.pagination.lastPage,"total":_vm.pagination.total,"per-page":_vm.pagination.perPage,"on-navigate":function (page) { return _vm.fetchCampaigns(page); }}}):_vm._e()],1):_c('CampaignSmsPromoter',{attrs:{"business-id":_vm.businessId}}),(_vm.deleteCampaignModal)?_c('MolModalConfirm',{attrs:{"title":_vm.deleteCampaignModal.name
        ? _vm.t('module.campaign.sms_list.delete_modal.are_you_sure', {
            campaignName: _vm.deleteCampaignModal.name,
          })
        : _vm.t('module.campaign.sms_list.delete_modal.are_you_sure_unnamed'),"label":_vm.t('module.campaign.sms_list.delete_modal.desc'),"confirm-button-label":_vm.t('module.campaign.sms_list.delete_modal.delete_cta'),"on-click-cancel":function () { return (_vm.deleteCampaignModal = null); },"on-click-confirm":_vm.handleDelete}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }