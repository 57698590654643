import { fetcher } from "@/core/fetcher";
import { responseTransformer } from "./response-transformer";
import type { IApiContract } from "./api-contract.type";
import type { ISearchParams } from "./search-params.type";
import type { IRequest } from "./request.type";
import { CampaignRepoError } from "../repo-error";
import { match } from "ts-pattern";
import { globalConfig } from "@/v2/core";
import {
  apiPaginationTransformer,
  reportAndReturnUnexpectedError,
  IDomainApiPaginatedResponse,
  IDomainPaginatedResourceList,
} from "@/module/common";
import { ICampaignList } from "../../domain/campaign";

const sortKeyHandler = (sortBy: IRequest["orderBy"]): ISearchParams["sort"] => {
  return match<IRequest["orderBy"], ISearchParams["sort"]>(sortBy)
    .with("name", () => "name")
    .with("audience", () => "audience")
    .with("cost", () => "cost")
    .with("status", () => "status")
    .with("scheduledTo", () => "scheduled_to")
    .with("-name", () => "-name")
    .with("-audience", () => "-audience")
    .with("-cost", () => "-cost")
    .with("-status", () => "-status")
    .with("-scheduledTo", () => "-scheduled_to")
    .otherwise(() => "-name");
};

const THIRTY_SECONDS = 1000 * 30;

export const fetchSmsCampaigns = async (
  request: IRequest
): Promise<IDomainPaginatedResourceList<ICampaignList>> => {
  try {
    const res = await fetcher.get<
      IDomainApiPaginatedResponse<IApiContract>,
      ISearchParams
    >(`marketing/businesses/${request.businessId}/sms-campaigns`, {
      cacheTTL: THIRTY_SECONDS,
      queryString: {
        per_page: request.perPage ?? globalConfig.defaultPaginationSize,
        page: request.page ?? 1,
        sort: sortKeyHandler(request.orderBy),
        ...(request.search ? { name: request.search } : {}),
      },
    });

    return {
      data: res.data.map((campaign) => responseTransformer(campaign)),
      pagination: apiPaginationTransformer(res.meta),
    };
  } catch (err: unknown) {
    const throwableError = reportAndReturnUnexpectedError<
      CampaignRepoError,
      CampaignRepoError["code"]
    >(CampaignRepoError, err, "fetchSmsCampaigns", "unknown");

    throw throwableError;
  }
};
