
















































































































































































































import { bemBuilder, toMoney, formValidation } from "@chatfood/core-utils";
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
} from "@vue/composition-api";
import {
  AtomLoading,
  AtomModal,
  AtomButton,
  AtomButtonTypeEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomTextTagEnum,
  AtomRadio,
  AtomDatepicker,
  AtomDropdown,
  MolMetricBox,
  MolFormControl,
  OrgFormInput,
  OrgFormTextArea,
  OrgHeaderInfo,
  AtomButtonSizeEnum,
} from "@/v2/new-design-system";
import { ICampaignDetail } from "../../domain/campaign";
import { ISenderId } from "../../domain/sender-id";
import { IChannel } from "../../domain/channel";
import { campaignRepo } from "../../repo";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { CampaignSmsPreview } from "../../component/sms-preview";
import { CampaignSmsCampaignReview } from "../../component/sms-campaign-review";
// import { CampaignSendTestSms } from "../../component/send-test-sms";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";
import { CampaignRouteEnum } from "../../route/campaign-route.enum";
import { useRouter } from "@/router";
import { Toast } from "@/design-system";

const css = bemBuilder("campaign-manager");

type IScheduleTime = { id: string; name: string };

export default defineComponent({
  name: "CampaignManager",
  components: {
    AtomModal,
    AtomText,
    AtomButton,
    AtomLoading,
    AtomRadio,
    AtomDropdown,
    AtomDatepicker,
    MolFormControl,
    OrgFormInput,
    OrgFormTextArea,
    OrgHeaderInfo,
    MolMetricBox,
    CampaignSmsPreview,
    CampaignSmsCampaignReview,
    // CampaignSendTestSms,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    campaignId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const campaign = ref<ICampaignDetail>();

    const scheduleDate = ref();
    const scheduleTime = ref<IScheduleTime>();
    const scheduleType = ref<"immediately" | "scheduled">("immediately");

    const scheduledTo = computed(() =>
      scheduleType.value === "immediately" ? undefined : scheduledDatetime.value
    );

    const formValues = reactive({
      title: "",
      content: "",
      senderId: "",
    });

    const senderIds = ref<ISenderId[]>();

    const fetchSenders = async (channel: IChannel) => {
      try {
        senderIds.value = await campaignRepo.getSenderIds({
          businessId: props.businessId,
          channel,
        });
        formValues.senderId = formValues.senderId ?? senderIds.value[0].id;
      } catch (e) {
        report(e);
        generalErrorToast();
      }
    };

    const senderSelected = computed(() =>
      senderIds.value?.length
        ? senderIds.value.find((sender) => sender.id === formValues.senderId)
        : undefined
    );

    const errors = ref({});

    const formConfig = {
      title: {
        required: {
          message: t("module.campaign.manager.required_error", {
            field: t("module.campaign.manager.campaign_title_input_label"),
          }),
        },
      },
      content: {
        required: {
          message: t("module.campaign.manager.required_error", {
            field: t("module.campaign.manager.content_input_label"),
          }),
        },
      },
      senderId: {
        required: {
          message: t("module.campaign.manager.required_sender_error"),
        },
      },
    };

    const loadRequiredResources = async () => {
      isLoading.value = true;

      try {
        campaign.value = await campaignRepo.getSmsCampaign({
          businessId: props.businessId,
          campaignId: props.campaignId,
        });

        if (campaign.value.state !== "draft") {
          goToMainSms();
          new Toast().create({
            type: "info",
            text: t("module.campaign.manager.can_not_edit_campaign_toast"),
          });
          return;
        }

        formValues.title = campaign.value.name;
        formValues.content = campaign.value.message;
        formValues.senderId = campaign.value.messagingServiceId ?? "";
        await fetchSenders(campaign.value.channel);

        const scheduledTo = campaign.value.scheduledTo;

        if (!scheduledTo) {
          setScheduleDate(new Date().toISOString().slice(0, 10));
          return;
        }

        scheduleType.value = "scheduled";
        scheduleDate.value = scheduledTo.toISOString().slice(0, 10);
        scheduleTime.value = availableTimeSlots.value.find(
          (slot) => slot.id === formatTime(scheduledTo)
        );
      } catch (e) {
        report(e);
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    };

    const estimatedTotalCost = computed(() => {
      if (!campaign.value || !senderSelected.value) {
        return "0";
      }

      return toMoney(
        senderSelected.value.unitPrice * campaign.value.audience * 100,
        senderSelected.value.currency
      );
    });

    const contentMaxChars = 133;
    const contentAvailableChars = computed(() => {
      return (formValues.content.length - 133) * -1;
    });

    onMounted(() => {
      loadRequiredResources();
    });

    // TODO: extract to fe-core
    function formatTime(date: Date): string {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
    }

    // TODO: extract to fe-core
    function getStartTime(date: Date): number {
      const today = new Date();

      if (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      ) {
        const minutes = today.getMinutes();
        const hours = today.getHours();

        return minutes < 30 ? hours : hours + 1;
      }

      return 0;
    }

    // TODO: extract to fe-core
    function startFromHalfHour(date: Date, currentHour: number): boolean {
      const today = new Date();

      if (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      ) {
        const minutes = today.getMinutes();
        const hours = today.getHours();

        return currentHour === hours && minutes < 30 ? true : false;
      }

      return false;
    }

    const availableTimeSlots = computed<IScheduleTime[]>(() => {
      if (!scheduleDate.value) {
        return [];
      }

      const date = new Date(scheduleDate.value);

      const startTime = getStartTime(date);
      const endTime = 23;
      const slotIncrement = 30;

      return Array.from({ length: endTime - startTime + 1 }, (_, i) => {
        const numberStartHour = i + startTime;
        const startHour = String(numberStartHour).padStart(2, "0");
        const endHour = String(i + startTime + 1).padStart(2, "0");

        const firstSlot = [];

        if (!startFromHalfHour(date, numberStartHour)) {
          firstSlot.push({
            id: `${startHour}:00`,
            name: `${startHour}:00 - ${endHour}:00`,
          });
        }

        return [
          ...firstSlot,
          {
            id: `${startHour}:${slotIncrement}`,
            name: `${startHour}:${slotIncrement} - ${endHour}:${slotIncrement}`,
          },
        ];
      }).flat();
    });

    const scheduledDatetime = computed(() => {
      return new Date(`${scheduleDate.value} ${scheduleTime.value?.id}`);
    });

    const setScheduleDate = (date: string) => {
      scheduleDate.value = date;

      if (!scheduleTime.value) {
        scheduleTime.value = availableTimeSlots.value[0];
        return;
      }

      scheduleTime.value =
        availableTimeSlots.value.find(
          (slot) => slot.id === scheduleTime.value?.id
        ) ?? availableTimeSlots.value[0];
    };

    const isFormValid = () => {
      errors.value = formValidation(formConfig, formValues);
      return !Object.keys(errors.value).length;
    };

    const onSaveCampaign = async () => {
      if (!isFormValid()) return;

      try {
        await campaignRepo.updateCampaign({
          businessId: props.businessId,
          campaignId: props.campaignId,
          title: formValues.title,
          senderId: formValues.senderId,
          content: formValues.content,
          scheduledTo: scheduledTo.value,
        });

        showReviewCampaign();
      } catch (e) {
        report(e);
        generalErrorToast();
      }
    };

    const isSendTestMessageVisible = ref(false);
    const showSendTestMessage = () => (isSendTestMessageVisible.value = true);
    const hideSendTestMessage = () => (isSendTestMessageVisible.value = false);

    const isReviewCampaignVisible = ref(false);
    const showReviewCampaign = () => (isReviewCampaignVisible.value = true);
    const hideReviewCampaign = () => (isReviewCampaignVisible.value = false);

    const router = useRouter();

    const goToMainSms = () => {
      router.push({
        name: CampaignRouteEnum.MAIN_SMS,
        params: {
          businessId: props.businessId,
        },
      });
    };

    return {
      t,
      css,
      isLoading,
      formValues,
      errors,
      scheduleDate,
      scheduleTime,
      goToMainSms,
      senderSelected,
      campaign,
      senderIds,
      scheduledTo,
      estimatedTotalCost,
      contentMaxChars,
      contentAvailableChars,
      scheduleType,
      scheduledDatetime,
      setScheduleDate,
      availableTimeSlots,
      onSaveCampaign,
      isSendTestMessageVisible,
      showSendTestMessage,
      hideSendTestMessage,
      isReviewCampaignVisible,
      showReviewCampaign,
      hideReviewCampaign,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonTypeEnum,
      AtomTextTagEnum,
      AtomButtonSizeEnum,
    };
  },
});
