






































































































import { bemBuilder } from "@chatfood/core-utils";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import {
  AtomLoading,
  AtomText,
  AtomDate,
  AtomMoney,
  AtomDateTypeEnum,
  MolTableAction,
  MolTableActionTypeEnum,
  MolPagination,
  OrgTable,
  useOrgTable,
  MolModalConfirm,
  type IOrgTableProps,
  type ISortDirection,
} from "@/v2/new-design-system";
import { ICampaignList } from "../../domain/campaign";
import { IDomainPagination } from "@/module/common";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { CampaignSmsPromoter } from "../../component/sms-promoter";
import { CampaignStateLabel } from "../../component/state-label";
import { IRepoFetchSmsCampaignsRequest } from "../../repo/fetch-sms-campaigns";
import { CampaignRouteEnum } from "../../route/campaign-route.enum";
import { campaignRepo } from "../../repo";
import { useRouter } from "@/router";
import { t } from "@/i18n";
import { Toast } from "@/design-system";

const css = bemBuilder("campaign-sms-list");

export default defineComponent({
  name: "CampaignSmsList",
  components: {
    AtomText,
    AtomDate,
    AtomMoney,
    AtomLoading,
    MolTableAction,
    MolPagination,
    OrgTable,
    CampaignSmsPromoter,
    CampaignStateLabel,
    MolModalConfirm,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);
    const isLoadingTable = ref(false);

    const campaigns = ref<ICampaignList[]>([]);
    const pagination = ref<IDomainPagination>();
    const currentSort = ref<IRepoFetchSmsCampaignsRequest["orderBy"]>("-name");

    const displayDeleteModal = ref(false);
    const deleteCampaignModal = ref<{
      id: string;
      name: string;
    } | null>(null);

    const router = useRouter();

    const { convertDirToString } = useOrgTable();

    const onTableSort = (key: string, direction: ISortDirection) => {
      const toSortBy = convertDirToString<
        IRepoFetchSmsCampaignsRequest["orderBy"]
      >(key, direction);

      const isSameCurrentSorting = currentSort.value === toSortBy;

      if (direction === "NONE" || isSameCurrentSorting) {
        return;
      }

      currentSort.value = toSortBy;
      fetchCampaigns();
    };

    async function fetchCampaigns(page = 1) {
      isLoadingTable.value = true;

      try {
        const res = await campaignRepo.getCampaigns({
          businessId: props.businessId,
          orderBy: currentSort.value,
          page,
        });

        campaigns.value = res.data;
        pagination.value = res.pagination;
      } catch (e) {
        generalErrorToast();
      } finally {
        isLoading.value = false;
        isLoadingTable.value = false;
      }
    }

    onMounted(() => {
      fetchCampaigns();
    });

    const columns = computed<IOrgTableProps["columns"]>(() => ({
      name: {
        header: () => t("module.campaign.sms_list.th_name"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      scheduledTo: {
        header: () => t("module.campaign.sms_list.th_scheduled_at"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      audience: {
        header: () => t("module.campaign.sms_list.th_audience_size"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      delivered: {
        header: () => t("module.campaign.sms_list.th_amount_delivered"),
        show: true,
        sortable: false,
        sortDirection: "NONE",
      },
      cost: {
        header: () => t("module.campaign.sms_list.th_total_cost"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      state: {
        header: () => t("module.campaign.sms_list.th_state"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
    }));

    function addMinutes(datetime: Date, minutes: number) {
      return new Date(datetime.getTime() + minutes * 60000);
    }

    function editCampaign(campaignId: string) {
      router.push({
        name: CampaignRouteEnum.SMS_MANAGER,
        params: {
          businessId: props.businessId,
          campaignId,
        },
      });
    }

    const handleDelete = async () => {
      if (!deleteCampaignModal.value) return Promise.resolve();
      const id = deleteCampaignModal.value.id;
      try {
        await campaignRepo.deleteSmsCampaign({
          businessId: props.businessId,
          campaignId: id,
        });

        new Toast().create({
          type: "success",
          title: t("module.campaign.sms_list.delete_modal.success_title"),
          text: t("module.campaign.sms_list.delete_modal.success_desc"),
        });
      } catch (e) {
        generalErrorToast();
      } finally {
        deleteCampaignModal.value = null;
      }
    };

    return {
      t,
      css,
      isLoading,
      isLoadingTable,
      campaigns,
      columns,
      pagination,
      addMinutes,
      onTableSort,
      editCampaign,
      fetchCampaigns,
      CampaignRouteEnum,
      AtomDateTypeEnum,
      displayDeleteModal,
      handleDelete,
      MolTableActionTypeEnum,
      deleteCampaignModal,
    };
  },
});
