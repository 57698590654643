








































































import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, ref } from "@vue/composition-api";
import {
  AtomMoney,
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
} from "@/v2/new-design-system";
import CampaignSmsPreview from "../sms-preview/SmsPreview.vue";
import { campaignRepo } from "../../repo";
import { report } from "@chatfood/bug-reporter";
import { Toast } from "@/design-system";
import { t } from "@/i18n";

const css = bemBuilder("campaign-sms-campaign-review");

export default defineComponent({
  name: "CampaignSmsCampaignReview",
  components: { CampaignSmsPreview, AtomButton, AtomText, AtomMoney },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    campaignId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    audience: {
      type: Number,
      required: true,
    },
    scheduledTo: {
      type: Date,
      default: null,
    },
    senderName: {
      type: String,
      required: true,
    },
    senderUnitPrice: {
      type: Number,
      required: true,
    },
    senderCurrency: {
      type: String,
      required: true,
    },
  },
  emits: {
    reviewed: () => true,
    close: () => true,
  },
  setup(props, { emit }) {
    const isLoading = ref(false);

    const onReview = async () => {
      isLoading.value = true;

      try {
        await campaignRepo.markCampaignAsReady({
          businessId: props.businessId,
          campaignId: props.campaignId,
        });

        new Toast().create({
          type: "success",
          text: props.scheduledTo
            ? t(
                "module.campaign.sms_campaign_review.scheduled_success_toast_message"
              )
            : t(
                "module.campaign.sms_campaign_review.immediately_success_toast_message"
              ),
        });

        emit("reviewed");
      } catch (e) {
        report(e);
        new Toast().create({
          type: "error",
          text: t("module.campaign.sms_campaign_review.error_toast_message"),
        });
      } finally {
        isLoading.value = false;
      }
    };

    const onDismiss = () => {
      emit("close");
    };

    return {
      t,
      css,
      onReview,
      onDismiss,
      isLoading,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
    };
  },
});
