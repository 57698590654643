import camelcaseKeys from "camelcase-keys";
import { match } from "ts-pattern";
import { ISenderId } from "../../domain/sender-id";
import { IApiContract } from "./api-contract.type";

const channelHandler = (
  channel: IApiContract["channel"]
): ISenderId["channel"] => {
  return match<IApiContract["channel"], ISenderId["channel"]>(channel)
    .with("Sms", () => "sms")
    .with("WhatsApp", () => "whatsapp")
    .with("Email", () => "email")
    .otherwise(() => "sms");
};

export const responseTransformer = (resource: IApiContract): ISenderId => {
  return {
    ...camelcaseKeys(resource, { deep: true }),
    channel: channelHandler(resource.channel),
  };
};
