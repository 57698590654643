















import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent } from "@vue/composition-api";
import { t } from "@/i18n";
import { MolTabNavigation, OrgHeaderInfo } from "@/v2/new-design-system";
import { CampaignRouteEnum } from "../../route/campaign-route.enum";

const css = bemBuilder("campaign-main");

export default defineComponent({
  name: "CampaignMain",
  components: {
    MolTabNavigation,
    OrgHeaderInfo,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const tabs = computed(() => [
      {
        routeName: CampaignRouteEnum.MAIN_SMS,
        title: t("module.campaign.main.tab_label_sms"),
      },
      {
        routeName: CampaignRouteEnum.MAIN_WHATSAPP,
        title: t("module.campaign.main.tab_label_whatsapp"),
      },
      {
        routeName: CampaignRouteEnum.MAIN_EMAIL,
        title: t("module.campaign.main.tab_label_email"),
      },
    ]);

    return {
      t,
      css,
      tabs,
    };
  },
});
